import en from './locales/en-US.json'
import es from './locales/es-ES.json'

export default defineI18nConfig(() => ({
  fallbackLocale: 'en',
  fallbackFormat: 'en',
  messages: {
    en,
    es,
  },
  datetimeFormats: {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      date: {
        weekday: 'long',
      },
    },
    es: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      date: {
        weekday: 'long',
      },
    },
  },
}))
